import { NullOrUndefinedValueError, AssertionError } from "./errors.js";
/**
 * Explicitly marks a promise as something we won't await
 * @param _promise
 */
export function spawn(_promise) { } // eslint-disable-line
/**
 * Explicitly mark that a cast is safe.
 * e.g. `safeCast(x as string[])`.
 */
export function safeCast(x) {
    return x;
}
/**
 * Marks that a cast should be checked at runtime.
 * Usually this is at some system boundary, e.g. a message received over the network.
 */
export function uncheckedCast(x) {
    return x;
}
/**
 * Asserts that a condition is true.
 * @param cond
 * @param msg
 */
export function assert(cond, msg) {
    if (!cond) {
        // eslint-disable-next-line no-debugger
        debugger;
        throw new AssertionError(msg || "Assertion failed");
    }
}
/**
 * Asserts that a branch is never taken.
 * Useful for exhaustiveness checking.
 * @param _x
 */
export function assertNever(_x) {
    throw new Error("unexpected branch taken");
}
/**
 * Asserts that a value is not null or undefined.
 * @param x
 * @param msg
 * @returns
 */
export function ensure(x, msg) {
    if (x === null || x === undefined) {
        // eslint-disable-next-line no-debugger
        debugger;
        throw new NullOrUndefinedValueError(`Value must not be undefined or null${msg ? `- ${msg}` : ""}`);
    }
    else {
        return x;
    }
}
